
import { defineComponent } from "vue";
import Datatable from "@/components/kt-datatable/KTDatatable_coursecategory.vue";
import AddCourseTypeDrawer from "@/layout/header/partials/course/AddCourseDrawer.vue";
import EditCourseDrawer from "@/layout/header/partials/course/EditCourseDrawer.vue";
import ApiService from "@/core/services/ApiService";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { object } from "yup/lib/locale";

export default defineComponent({
  name: "course-categories",
  components: {
    Datatable,
    AddCourseTypeDrawer,
    EditCourseDrawer,
  },
  data() {
    return {
      componentKey: 0,
      tableHeader: [
        {
          name: "Actions",
          key: "actions",
          sortable: false,
          width: "120px",
        },
        {
          name: "Category Name",
          key: "category_type",
          sortable: true,
        },
      ],
      lists: [],
      search: "",
      tableData: [],
      data: {},
    };
  },
  async created() {
    await this.getCategoryData();
    this.emitter.on("category-added", async () => {
      await this.getCategoryData();
    });
  },
  methods: {
    async getCategoryData() {
      await ApiService.get("configurations/category_type/list")
        .then((response) => {
          this.tableData = response.data.data;
          // Object.assign(this.tableData, this.lists);
          this.componentKey += 1;
        })
        .catch(({ response }) => {
          console.log(response);
        });
    },
    editCategory(data) {
      this.emitter.emit("category-edit-type", data);
    },
    searchItems() {
      if (this.search !== "") {
        let results: never[] = [];
        for (let j = 0; j < this.tableData.length; j++) {
          if (this.searchingFunc(this.tableData[j], this.search)) {
            results.push(this.tableData[j]);
          }
        }
        this.tableData.splice(0, this.tableData.length, ...results);
      } else {
        Object.assign(this.tableData, this.lists);
      }
    },

    searchingFunc(obj, value) {
      for (let key in obj) {
        if (!Number.isInteger(obj[key]) && !(typeof obj[key] === "object")) {
          if (obj[key].toLowerCase().indexOf(value.toLowerCase()) != -1) {
            return true;
          }
        }
      }
      return false;
    },

    view(divison) {
      this.data = divison;
    },

    DeleteCategory(id) {
      Swal.fire({
        title: "Are you sure you want to delete it?",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Yes, delete!",
      }).then((result) => {
        if (result.isConfirmed) {
          ApiService.delete("configurations/category_type/delete/" + `${id}`)
            .then((response) => {
              this.emitter.emit("category-added", true);
              Swal.fire("Deleted!", response.data.data, "success");
            })
            .catch(({ response }) => {
              console.log(response);
            });
        }
      });
    },
  },
});
